.clinic {
    background-color: white;
    height: 100vh;
    margin-left: 15px;
    padding: 20px 20px 20px 0px;

    &__header {
        padding: 10px;

        &__search {
            background-color: #F5F6FA;
            border-radius: 30px;
            padding: 5px 10px;
            width: 180px;
            height: 35px;
            border: none;
            // color: red;
            .ant-input {
            background-color: #F5F6FA;

            }
        };

        .ant-select {
            width: 200px;
            // &:hover {
            //     border: 1px solid $main-color !important;
            // }
        }
    }

}