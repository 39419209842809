.wrapper-common-input {
    .common-input {
    background-color: #F4F7FF;
    color: #333;
    border-radius: 10px;
    width: 100%;
    height: 40px;
    font-weight: 600;
}
   
}