@font-face {
  font-family: 'SVN-Gilroy';
  font-weight: $font-weight-light;
  src: url('../../fonts/SVN-Gilroy-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'SVN-Gilroy';
  font-weight: $font-weight-normal;
  src: url('../../fonts/SVN-Gilroy-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'SVN-Gilroy';
  font-weight: $font-weight-medium;
  src: url('../../fonts/SVN-Gilroy-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'SVN-Gilroy';
  font-weight: $font-weight-semibold;
  src: url('../../fonts/SVN-Gilroy-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'SVN-Gilroy';
  font-weight: $font-weight-bold;
  src: url('../../fonts/SVN-Gilroy-Bold.ttf') format('truetype');
}
