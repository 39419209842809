
.form {
  gap: 10px;
  .wrapper {
    padding: 14px 21px;
    border: 1px solid #e2e2e2;
    border-radius: 10px;
  }
  h2 {
    font-weight: 700;
    font-size: 18px;
    color: #2c3782;
    margin: 8px 0 5px;
  }
  h3 {
    font-weight: 600;
    font-size: 18px;
    color: #2c3782;
  }
  label {
    font-weight: 700;
    font-size: 14px;
    color: #2c3782;
  }
  input {
    background: #f5f7fb;
    font-weight: 500;
  }
  textarea {
    //width: 641px;
    height: 88px;
    background: #f5f7fb;
    border-radius: 10px;
    font-weight: 500;
  }
  .add-btn {
    width: 114px;
    height: 26px;
    color: #6576ff;
    font-weight: 700;
  }
  .menu {
    display: flex;
    border-inline-end: none !important;
    li {
      padding-inline: 0;
      text-align: center;
      display: grid;
      background: #f4f6fa;
      font-weight: 600;
      justify-content: center;
    }
    span {
      font-size: 14px;
    }
    &-phan_quyen {
      li {
        max-width: 150px;
      }
    }
  }
  :global(.ant-menu-light .ant-menu-item-selected) {
    color: white;
    background: #2c3782;
  }
  :global(.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
    background: #f5f7fb;
    font-weight: 500;
    span {
      font-size: 14px;
    }
  }
  :global(.ant-picker) {
    background: #f5f7fb;
  }
  .add-btn {
    width: 114px;
    height: 26px;
    color: #6576ff;
    font-weight: 700;
    display: flex;
    align-items: center;
    &:hover {
      color: #6576ff;
      opacity: 0.7;
    }
  }
  :global(.ant-input-disabled),
  :global(.ant-select-selector),
  :global(.ant-picker .ant-picker-input > input[disabled]),
  :global(.ant-input-number-disabled) {
    color: #2c3782 !important;
    background-color: #f5f7fb !important;
    border: none;
  }
  :global(.ant-input-number-focused) {
    border-color: #8fa0ff;
    border-inline-end-width: 1px;
  }
}

.qlph-wrapper {
  margin-top: 10px;
  width: 100%;
  min-height: calc(100vh - 250px);
  background: #f4f6fa;
  border-radius: 10px;
  padding: 12px;
  :global(.ant-tree) {
    background: transparent;
  }
  :global(.ant-tree .ant-tree-indent) {
    //display: none;
  }
  :global(.ant-tree .ant-tree-checkbox + span) {
    pointer-events: none;
    font-weight: 600;
  }
  :global(.ant-checkbox-wrapper) {
    font-weight: 600;
  }
  .checkbox-lis {
    margin: 8px;
    padding: 10px 16px;
    background: white;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
  }
  [class*=" ant-col"] {
    font-size: 12px;
    font-weight: 600;
  }
  :global(.ant-col-2),
  :global(.ant-col-3),
  :global(.ant-col-4) {
    text-align: center;
  }
  .header-row {
    background: white;
    height: 36px;
    padding: 10px;
    border-radius: 5px;
  }
}

.ant-select-item {
  padding: 5px 12px;
  cursor: pointer;
  &:hover {
    background: rgba(44, 55, 130, 0.04);
  }
}

.tree-select {
  :global(.ant-select-selection-item) {
    color: #fff;
    background-color: #4263eb !important;

    :global(.anticon) {
      color: #fff;
    }
  }

  :global(.ant-select-selection-item-remove) {
    color: #fff;
  }
}

.wrapperPreviewSignature {
  position: relative;
  .close-outlined {
    padding: 16px;
    cursor: pointer;
    z-index: 1000;
    position: absolute;
    right: 0;
    top: 0;
  }
}
