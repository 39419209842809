.modal__import {
  .ant-modal-content {
    border-radius: 10px !important;
  }

  .ant-modal-body {
    padding: 0 !important;

  }

  .ant-modal-close {
    display: none !important;
  }

  .ant-modal-footer {
    border: none !important;
  }

  &__header {
    width: 100%;
    padding: 10px 10px 10px 20px;
    background-color: #050C42;
    border-radius: 10px;

    h4 {
      color: #FFFFFF;
    }
  }

  &__cancel {
    width: 143px;
    height: 40px;
    border-radius: 10px;
    border : 1px solid $main-color !important;
    color: $main-color !important;
    font-weight: 700;

  }
  &__save {
    width: 143px;
    height: 40px;
    border-radius: 10px;
    background-color: $main-color !important;
    color: #FFF !important;
    font-weight: 700;
  }

  &__wraptext{
    margin: 10px;
    width: 100%;
    p {
      font-size: 14px;
    }
    span {
      margin-bottom: 5px !important;
      color: blue;
      text-decoration: underline;
      cursor: pointer;
    }

  }

  &__upload {
    &__item{
      font-size: large;
      color: #27c456;
    }
  }

  .ant-upload-drag {
    border: 1.2px dashed #27c456 !important;
    background-color: #EFFDF5 !important;
    border-radius: 10px;
  }
}
.file_contract {
  height: 54px;
  background: #FFFFFF;
  border: 1px solid #D8DCE6;
  border-radius: 10px;
  padding-inline: 10px;
  margin-bottom: 12px;

  img {
    width: 30px;
    height: 30px;
    margin-right: 15px;
  }
  p {
    font-weight: 600;
    font-size: 13px;
    color: #050c42;
    margin-bottom: -5px;
  }
  span {
    @extend p;
    color: #999999;
    //margin-top: -100px !important;
  }
  .icon_action_file{
    margin-right: 20px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}
