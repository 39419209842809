.register {
  // background-color: red;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;

  @media only screen and (max-width:680px) {
    .responsive-text-content {
      display: block;
    }
  }
  &__left {
    max-width: 500px;
    background-color: #4971ed;
    position: -webkit-sticky;
    position: sticky;


    padding-top: 30px;
    z-index: 10;
    @media only screen and (max-width:992px) {
      display: none;
    }

    &__logo {
      width: 200px;
      //height: 40px;
      // margin-left: 20px;
      aspect-ratio: auto;
    }

    &__title {
      font-weight: 600;
      font-size: 36px;
      line-height: 36px;
      text-align: center;
      letter-spacing: 0.1px;
      color: #ffffff;
      margin:40px 40px 0 40px;
      @media only screen and (max-width:1100px) {
        font-size: 25px;
        line-height: 25px;
      }
      //margin-top: 30px;
    }

    &__content {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #ffffff;
      text-align: center;
      margin: 20px 50px;
      @media only screen and (max-width:1100px) {
      font-size: 13px;
    }
    }

    &__devices {
      //width: 80%;

      width: 74%;
      position: absolute;
      bottom: 0px;
      right: 0;

      @media only screen and (max-height:768px) {
        width: 65%;
      }

      
      @media only screen and (max-height:669px) {
        width: 55%;
      }
    }
  }

  .responsive-text-content {
    display: none;
  }

  .register__wrapper {
    background-color: transparent;
    display: flex;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    max-height: calc(100vh - 82px);
    @media only screen and (max-width:1100px) {
      max-height: 100vh;
    }
    @media only screen and (max-width:576px) {
      min-height: calc(100vh + 200px);
    }
  }

  &__right {
    height: calc(100vh - 82px);
    display: flex;
    background-color: transparent;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;

    &__content {
      width: 45%;
      .ant-radio-wrapper {
        font-size: 16px;
      }
      .ant-checkbox-wrapper {
        font-size: 16px;
      }
      @media only screen and (max-width:1100px) {
        padding: 20px;
        width: 100%;
      }

      &__title {
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 32px;
        letter-spacing: 0.1px;
        color: #333333;
      }

      &__textcontent {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: #333333;
      }

      &__titleselect {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.1px;
        color: #333333;
      }
    }
  }

  &__footer {
    &--step {
      display: flex;
      position: fixed;
      left: 0;
      z-index: 1;
      right: 0;
      height: 82px;
      bottom: 0;

      justify-content: flex-end;
      background: #ffffff;
      box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);

      &__btn-group {
        display: flex;
        gap: 10px;
        margin: 20px 40px;
      }

      @media only screen and (max-width:680px) {
      }
    }
    &--btn {
      width: 154px;
      height: 42px;
      border-radius: 5px;
    }
  }
}
