.header {
    display: flex;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    justify-content: center;
    height: fit-content;
    padding-bottom: 4px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
}

.wrapper-container {
    height: calc(100vh - 160px);

    @media only screen and  (max-width:990px) {
        height: calc(100vh - 65px);
    }
    @media only screen and  (max-height:600px) {
        height: calc(100vh - 65px);
    }
    overflow: hidden;
    overflow-y: scroll;
    display: flex;
    .container {
        margin: auto;
        margin-top: 32px;
        width: 560px;
    }
}

.footer {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    height: 82px;
    @media only screen and  (max-width:960px) {
    height: 60px;
    }
    @media only screen and  (max-height:600px) {
        height: 60px;
    }
    background-color: #fff !important;
    box-sizing: border-box;
    .footer-group-btn {
        justify-content:end;
        padding-right: 20px;
        width: 100%;
        height: 100%;
    }
    .footer-btn {
        background: #4971ED;
        border-radius: 5px;
        width: 154px;
        height: 42px;
        @media only screen and  (max-width:960px) {
            width: 105px;
            height: 32px;
        }
        @media only screen and  (max-height:600px) {
            width: 105px;
            height: 34px;
        }
    }
}