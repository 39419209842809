.table__custom {
  .ant-table-body {
    overflow-y: auto !important;
  }

  .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
    width: 0 !important;
  }

  .ant-table-thead {
    min-height: 45px;
    .ant-table-cell {
      background: #f3f6fd;
      color: #050c42;
      font-size: 14px;
      font-weight: 600;
      border: none !important;

      &:first-child {
        border-top-left-radius: 10px !important;
        border-bottom-left-radius: 12px !important;
      }

      &:last-child {
        border-top-right-radius: 10px !important;
        border-bottom-right-radius: 12px !important;
      }
    }
  }

  .selectedRow {
    td {
      color: #fff !important;
      background: #2C80FF !important;
    }
  }

  .ant-table-cell-row-hover {
    color: #fff !important;
    background-color: #2C80FF !important;
    svg {
      color: #fff !important;
    }
  }

  .ant-table-tbody {
    .ant-table-cell {
      color: #050c42;
      font-size: 14px;
      font-weight: 600;
      border: none;

      &:first-child {
        border-top-left-radius: 10px !important;
        border-bottom-left-radius: 12px !important;
      }

      &:last-child {
        border-top-right-radius: 10px !important;
        border-bottom-right-radius: 12px !important;
      }
    }
  }

  .ant-table-body {
    //${customScrollbar}
  }

  .ant-table-pagination.ant-pagination {
    margin: 12px 0;
    padding: 6px 0;
  }

  .row-table {
    cursor: pointer;
  }
}