.back-btn {
    transition: all 0.3s ease-in-out;
    background-color: #fff;
    border: none;
    border-radius: 50%;
    min-width: fit-content;
    min-height: fit-content;
    width: 32px;
    height: 32px;
    cursor: pointer;
    border: 1px solid #E3e3e4;

    &:hover, :focus {
        border: 1px solid #6576FF;
        background-color: #fff;
    }
}