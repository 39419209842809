.main__sider {
  height: 100vh;
  // min-width: $main-sider-width !important;
  background-color: $white-color;
  &__logo {
    cursor: pointer;    
  }

  .ant-layout-sider-children {
    width: 100% !important;
    padding: 10px 20px;
    height: 100vh;
    background-color: $white-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 4px 40px rgba(12, 73, 246, 0.1);
    border-radius: 23px;

  }

  .ant-menu {
    border: none !important;
  }

  &__logo {
    width: 200px;
    margin-left: -15px;
  }

  &__menu {
    width: 100%;

    //background-color: green;

    .ant-menu-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 20px;
    }

    .ant-menu-title-content {
      font-weight: 500;
      font-size: 14px;
      line-height: 15px;
      color: #333333;
    }

    .ant-menu-item-selected {
      background-color: #FFFFFF;
      padding: 0;

      .ant-menu-title-content {
        font-weight: 500;
        font-size: 14px;
        line-height: 15px;
        color: $main-color;
        //margin-left: 5px;
      }
    }

    &__border__active {
      width: 3px;
      height: 26px;
      background: #4971ED;
      border-radius: 28px;
    }

    &__icon__active {
      width: 35px;
      height: 35px;
      background: #2C80FF;
      border-radius: 10px;
      display: flex;
      margin-left: 5px;
      margin-right: 10px;
      align-items: center;
      justify-content: center;

      svg {

        path:nth-of-type(even),
        path:nth-of-type(odd) {
          stroke: white;
        }
      }
    }
  }

  &__info {
    width: 100%;
    background-color: $main-color;
    border-radius: 17px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
    color: $white-color;

    p {
      font-weight: 500 !important;
      font-size: 14px !important;
      line-height: 15px;
    }
  }
}
.main__sider__menu--collapse {
  .ant-menu-item{
    padding: 10px;
    display: flex;
    svg {
      margin: auto;
    }
    span {
      display: none !important;
    }
    &.ant-menu-item-selected {
      background-color: $main-color;
      svg {
        path:nth-of-type(even),
        path:nth-of-type(odd) {
          stroke: white;
        }
      }
    }
  } 
}
.main__sider--dark {
  .ant-layout-sider-children {
    position: relative;
    z-index: 10;
    box-shadow:0px 4px 40px rgba(12, 73, 246, 0.1);
    background-color: initial;
  }
}