@mixin style-scroll-bar-hide($wid: 10px,$height: 10px, $thumb: #f6f8fe) {
  /* width */
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    width: $wid;
    height: $height !important;
    cursor: pointer !important;
  }
  &::-webkit-scrollbar-corner {
    background-color: transparent !important;
  }
  /* Handle */
  &::-webkit-scrollbar-track {
    color: transparent;
    background-color: transparent;
    cursor: pointer !important;
    &:hover {
      cursor: pointer !important;
      background-color: #ddd;
    }
  }

  &::-webkit-scrollbar-thumb {
    background: $thumb;
    cursor: pointer !important;
    &:hover {
      cursor: pointer !important;
      background-color: #a7aab1;
    }
    transition: all 1s linear;
  }
}

.center-header {
  display: flex;
  justify-content: center;
  text-align: center;
  b {
    font-size: 32px;
    font-weight: 600;
  }
}

.form-container {
    max-width: 100%;
    overflow: auto;
    padding-inline: 40px;
    padding-bottom: 20px;
    max-height: calc(100vh - 190px);
    @include style-scroll-bar-hide();
}

.form-footer {
    background-color: #fff;
    display: block;
    position: absolute;
    bottom: 0px;
    right: 0;
    left: 0;
    height: 60px;
    max-height: 82px;
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.05)
}

.form__item--clinic__info {
  label {
    font-weight: 600 !important;
  }
  :global(.ant-select-selector) {
    border-radius: 10px;
  }

  :global(.ant-form-item-label) {
    padding: 0 0 12px;
    span {
      font-size: 13px !important;
    }
  }

  :global(.ant-form-item-control-input-content) {
    input {
      border-color:transparent;
      padding: 10px 5px;
      border-radius: 10px;
      background-color: #fff !important;
    }

  }
}

@media only screen and  (max-width:1100px) {
  .form__item--clinic__info {
    label {
      font-weight: 600 !important;
    }
    :global(.ant-select-selector) {
      border-radius: 10px;
    }
  
    :global(.ant-form-item-label) {
      padding: 0 0 6px;
      span {
        font-size: 12px !important;
      }
    }
  
    :global(.ant-form-item-control-input-content) {
      input {
        border-color:transparent;
        padding: 8px 4px;
        border-radius: 10px;
        background-color: #fff !important;
      }
  
    }
  }
}

.select-clinic {
  :global(.ant-select-selection-placeholder) {
    z-index: 1;
    align-self: center;
  }
  
  :global(.ant-select-selector) {
    height: 40px !important;
    background-color: #fff !important;
    input {
      align-self: center;
    height: 40px !important;
  }
  :global(.ant-select-selection-item) {
    align-self: center;
  }
  }
}

.wrapper-specialist {
  padding: 16px 32px !important;
  border-radius: 6px;
  border: 1px dashed #ebecee;
}

.wrapper-specialist--mode1 {
  border: none;
  background-color: #fff;
}

.tree-select {
  width: 100% !important;
  :global(.ant-select-selector) {
    background-color: #f4f7ff !important;
    outline: none;
    border: 1px solid transparent !important;
    color: #333;
    border-radius: 10px;
    width: 100%;
    height: 40px;
    font-weight: 600;

    :global(.ant-select-selection-item) {
      background-color: #4971ed !important;
      color: #fff;
      height: 35px;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      padding: 6px 4px;
      border-radius: 8px;
      svg {
        transform: scale(0.8);
        color: #fff;
        padding-top: 5px;
      }
    }
  }
}

.wrapp-list {
  background-color: #fff;
  border-radius: 10px;
  padding: 25px 0 25px 36px !important;
  :global(.ant-checkbox-group) {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    .wrap-checkbox {
      flex: 1 2 45%;
    }
  }

  &__child {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  :global(.ant-tree-list-holder-inner) {
    :global(.ant-tree-treenode) {
      flex: 50%;
    }
    :global(.parent) {
      width: 100%;
    }
    .ant-tree {
      width: 100% !important;
    }
  }
}

.input {
  background-color: #fff !important;
  height: 40px;
  font-weight: 500;
  // border: 1px solid transparent;
  input {
    background-color: #fff;
  }
  
}

.sub-title {
  font-weight: 600;
  padding-block: 6px;
}

.custom__tree__select {
  background-color: #fff;
    border-radius: 10px;
    border: none;
    min-height: 40px;
    :global(.ant-select-selector) {
    min-height: 44px !important;
    background-color: #fff !important;
    border: transparent !important;
    border-radius: 10px;
    :global(.ant-select-selection-item) {
      background-color: #4971ed;
      color:#fff;
      svg {
        fill : #fff;
      }
    }
  }
}