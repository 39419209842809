.nav {
  background-color: $header-bg;
  border-top: 1px solid #262D64;
  .ant-menu-submenu-open {
    color: $white-color !important;
  }

  li {
    padding-left: 30px !important;

    .ant-menu-title-content {
      color: $white-color;
      img {
        filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(10%) hue-rotate(211deg) brightness(103%) contrast(105%);
      }
      a {
        color: $white-color;
      }
    }

    .ant-menu-submenu-title {
      @extend %align-items-center;
      &:hover {
        .ant-menu-title-content {
          color: $main-color !important;
        }

        img {
          filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(298deg) brightness(98%) contrast(104%);
        }
      }
    }

    a {
      @extend %align-items-center;

      img {
        margin-right: 10px;
      }
    }

    &:hover {
      .ant-menu-title-content {
        a {
          color: $main-color !important;

          img {
            filter: invert(65%) sepia(7%) saturate(2735%) hue-rotate(124deg) brightness(97%) contrast(87%);
          }
        }
      }
    }

    &::after {
      display: none !important;
    }
  }

  .ant-menu-item-selected {
    a {
      color: $main-color !important;
      img {
        filter: invert(65%) sepia(7%) saturate(2735%) hue-rotate(124deg) brightness(97%) contrast(87%);
      }
    }
  }

  .ant-menu-submenu-selected {
    .ant-menu-title-content {
      color: $main-color !important;
      a {
        span {
          color: $main-color !important;
        }
      }
    }

    .ant-menu-submenu-arrow {
      color: $main-color !important;
    }

    img {
      filter: invert(65%) sepia(7%) saturate(2735%) hue-rotate(124deg) brightness(97%) contrast(87%);
    }
  }

  .ant-menu-overflow-item {
    @extend %align-items-center;
  }


  .ant-menu-submenu-horizontal .ant-menu-submenu-arrow {
    display: block;
  }
  .ant-menu-submenu-arrow {
    top: 52%;
    right: -15px;
    transform: translateY(-50%) rotate(90deg);
  }

}

.ant-menu-submenu-popup {
  min-width: 0 !important;

  .ant-menu {
    background: $white-color;
    box-shadow: -8px 4px 50px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 20px 15px;

    .ant-menu-title-content {
      a {
        @extend %flex-row-wrap;
        column-gap: 10px;

        &:hover {
          img {
            filter: invert(65%) sepia(7%) saturate(2735%) hue-rotate(124deg) brightness(97%) contrast(87%);
          }
        }
      }
    }

    .ant-menu-item-selected {
      background: none;

      .ant-menu-title-content {
        a {
          @extend %flex-row-wrap;

          color: $main-color !important;
          column-gap: 10px;
        }

        &:hover {
          color: $main-color !important;
        }
      }
    }

    li {
      height: 25px;
      line-height: 25px;
      margin: 0;
      padding: 0;

      &:hover {
        background: none;
      }
    }
  }
}
.ant-menu-submenu-arrow, .ant-menu-submenu-expand-icon {
  color : #FFF
}
.anticon .anticon-ellipsis {
  svg {
    color : #FFF
  }
}

.ant-menu-item a {
  &:hover {
    color: $main-color !important;
  }
}
