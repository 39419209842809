.login {
  height: 100vh;
  width: 100%;
  background-color: $white-color;
  display: flex;
  flex-direction: row;

  &__form {
    //background-color: red;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__logo {
      // width: 100px;
      height: 100px;
    }

    &__title{
      font-weight: 600;
      font-size: 32px;
      line-height: 34px;
      /* identical to box height */
      letter-spacing: 0.1px;
      color: #050C42;
    }

    &__description {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      margin-top: 10px;
      letter-spacing: 0.1px;
      color: #050C42;
      opacity: 0.5;
    }

    &__body {
      margin-top: 20px !important;
      
      .ant-form-item-row{
        margin-top: 25px;
      }
      &__input {
        height: 45px;
        border-color: #FFF;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        background-color: #F5F7FB;
        letter-spacing: 0.1px;
        color: #050C42;
        .ant-input {
          background-color: #F5F7FB;
        }
       
      }

      &__texterror {
        color: red;
        font-size: 14px;
        text-align: center;
        margin-top: 20px;
      }

      &__button {
        width: 434px;
        height: 45px;
        background: #6576FF;
        border-radius: 10px;
        margin-top: 20px;
      }
    }
  }

}
