.account {
    height: 100vh;
    width: 100%;
    &__body {
        padding: 30px 15px;
        //background-color: red;
        h3 {
            font-weight: 600;
            font-size: 32px;
            line-height: 34px;
            letter-spacing: 0.1px;
            color: #333333;
        }
        p {
            font-weight: 600;
            font-size: 18px;
            line-height: 19px;
            letter-spacing: 0.1px;
            color: #4971ED;
        }
        &__form {
            margin-top: 25px;

            input {
                background: #F4F7FF;
                border-radius: 10px;
                border-color: #FFF;
                height: 45px;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.1px;
                color: #333333;

            }
        }
    }
}

.modal__account{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__title {
        font-weight: 600;
        font-size: 25px;
        line-height: 29px;
        letter-spacing: 0.1px;
        color: #333333;
        margin-top: 20px;
    }

    &__body {
        width: 70%;
        margin-block: 20px;

        .ant-form-item-row {
            margin-top: 10px;
        }

        input {
            background: #F4F7FF;
            border-radius: 10px;
            border-color: #FFF;
            height: 45px;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.1px;
            color: #333333;
        }
        .ant-input-password{
            background: #F4F7FF;
            border-radius: 10px;
            border-color: #FFF;
            //height: 45px;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.1px;
            color: #333333;
            
            input {
                height: 35px;
            }
        }

        &__button{
            width: 170px;
            border-radius: 10px;
            height: 45px;
            font-weight: 600;
            font-size: 14px;
        }
    }
}