html {
  font-size: 10px;
}

body {
  background-color: $primary-bg-color;
  font-size: 1.4rem;
  font-family: $font-body !important;
  font-weight: 500;
  color: $text-primary;
  //letter-spacing: 0.1rem;
}

h1,
h2,
h3,
h4,
h5 {
  font-size: 1.8rem;
  font-weight: $font-weight-bold;
  color: $text-primary;
}

::-webkit-scrollbar {
  //width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: $gray-light-color;
  border-radius: 34px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: $gray-light-color;
}
.ant-pagination .ant-pagination-item-active {
  background-color: $main-color !important;
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.ant-btn:focus,
.ant-btn:hover,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
  //border-color: $green-color;
  box-shadow: none !important;
  cursor: pointer;
}

.ant-btn {
  height: 35px;
  padding: 0px 10px;
}
.ant-btn:focus {
  //color: $white-color;
}
.ant-checkbox-checked .ant-checkbox-inner {
  // background-color: $main-color;
  // border-color: $main-color;
}

.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:focus {
  box-shadow: 0 0 0 2px #4bb7ab40;
}

.ant-form-item {
  margin: 0;
}

.ant-form-vertical .ant-form-item-label {
  padding: 0 0 6px;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner {
  background-color: #27c456 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: #27c456 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: #1f9643e5 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e6ffe8 !important;
}
.ant-picker-panel,
.ant-picker-cell-inner,
.ant-picker-content th,
.ant-picker-header-view {
  font-family: $font-body !important;
  font-weight: $font-weight-semibold !important;
}

.ant-picker-ok .ant-btn {
  border-color: unset;
  background: $main-color;
}

.ant-empty-normal {
  margin: 10px 0;
}

.ant-modal-close-x {
  display: none !important;
}
.ant-layout-sider {
  //background-color: #FFFFFF !important;
}

.container {
  padding: 0 20px;
  &--content {
    margin-top: 26px;
  }
}

.button-header {
  width: 151px;
  height: 42px;

  background: #2c80ff;
  border-radius: 12px;
}

.container-modal {
  padding: 6px 18px;
}

.ant-header {
  background-color: transparent !important;
}