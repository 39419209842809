.comming__appointment{
    padding : 20px;

    h3{
        font-weight: 600;
        font-size: 20px;
        line-height: 21px;
        letter-spacing: 0.1px;
        color: #333333;
        margin-top: 20px;
        
    }
}