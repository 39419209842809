.wrapper {
  .init-clinic {
    label {
        font-size: 16px;
        font-weight: 600;
    }
    .radio-specialist {
      :global(.ant-space-vertical) {
        width: 100%;
        flex-direction: row;
        :global(.ant-space-item) {
          width: 50%;
          :global(.ant-radio-wrapper) {
            background-color: #fff;
          }
          :global(.ant-radio-wrapper-checked) {
            background-color: #4971ED;
          }
          :global(.ant-radio-inner) {
            &::after {
              transform: scale(0.6);
            }
          }
        }
      }
    }

    .wrapper-specialist {
      padding: 16px 32px !important;
      border-radius: 6px;
      border: 1px dashed #ebecee;
    }

    .wrapper-specialist--mode1 {
      border: none;
      background-color: #fff;
    }
  }
  .input {
    background-color: #fff;
    height: 40px;
    border: 1px solid transparent;
    input {
      background-color: #fff;
    }
    
  }
  .common-select {
    :global(.ant-select-selector) {
      background-color: #fff !important;
    }
  }
  .tree-select {
    width: 100% !important;
    :global(.ant-select-selector) {
      background-color: #f4f7ff !important;
      outline: none;
      border: 1px solid transparent !important;
      color: #333;
      border-radius: 10px;
      width: 100%;
      height: 40px;
      font-weight: 600;

      :global(.ant-select-selection-item) {
        background-color: #4971ed !important;
        color: #fff;
        height: 35px;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        padding: 6px 4px;
        border-radius: 8px;
        svg {
          transform: scale(0.8);
          color: #fff;
          padding-top: 5px;
        }
      }
    }
  }

  .wrapp-list {
    background-color: #fff;
    border-radius: 10px;
    padding: 25px 0 25px 36px !important;
    :global(.ant-checkbox-group) {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      .wrap-checkbox {
        flex: 1 2 45%;
      }
    }
  }
  .radio-wrapper {
    width: 100%;
    display: flex;
    padding-left: 36px;
    gap: 82px;
    :global(.ant-radio-inner) {
      background-color: #fff;
      &:after {
        background-color: #4971ED;
      transform: scale(0.6);
      }
    }
  }

  .sub-title {
    font-weight: 600;
    font-size: 16px;
  }
  
  //==================================================================

  .init-info {
    label {
      font-size: 16px;
      font-weight: 600;
  }
  }

  .list-hide {
    display: flex;
    height: 60px;
    width: 100%;
      position: relative;
      cursor: pointer;

    .list-hide-icon {
      position: absolute;
      bottom: 0;
      color:#4971ED;
    }
  }

}

.init-end {
  text-align: center;

  .heading-end {
    h2 {
      font-size: 32px;
      margin: auto;
      margin-bottom: 18px;
      max-width:310px;
      text-align:center;
      text-align:-webkit-center;
    }
  }
  .init-end-img {
    width: 10vw;
  }
}
.initClinic-header {
  @media only screen and  (max-width:990px) {
    display: none;
  }
    margin-top: 50px;
    .step {
    max-width: 580px;
    @media only screen and  (min-width:1366px) {
    width: 580px;
    }
    margin: auto;
    }
}

.otp-input-wrapper {
  justify-content: center;
  .otp-input {
    width: 50px !important;
    width: 50px;
    height: 50px;
  
    background: #f4f7ff;
    border-radius: 10px;
    border: 1px solid transparent;
    font-size: 20px;
  }

}

.space-wrapper {
  margin-top: 36px;
  sub {
    margin-top: 8px;
    display: block;
    text-align: center;
    font-style: italic;
  }
}

.tab-nav {
  :global(.ant-tabs-nav-wrap) {
    display: none !important;
  }

  :global(.ant-tabs-nav) {
    &::before {
      display: none !important;
    }
  }
}

.form-footer {
  background-color: #fff;
  display: block;
  position: absolute;
  bottom: 0px;
  right: 0;
  left: 0;
  height: 60px;
  max-height: 82px;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.05)
}

.footer-wrapper {
  height: 100%;
  max-width: calc(100vw - 515px);
}

@media only screen and (max-width: 1100px) {
  .footer-wrapper {
    height: 100%;
    max-width:calc( 100vw - 20px);
  }
}