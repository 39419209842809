.wrapper-modal-content {
}

.modal-header {
}

.modal-icon {
  font-size: 30px;
  margin-right: 10px;

  svg {
    color: #2c80ff;
  }
}

.modal-title {
  font-weight: 600;
  font-size: 32px;
  line-height: 30px;
  color: #333333;
}

.button {
  width: 151px;
  height: 36px;

  background: #2c80ff;
  border-radius: 6px;
}
