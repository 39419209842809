.appointment__home {
  background-color: $input-color;
  padding: 10px;
  border-radius: 20px;
  width: 100%;
  min-height: calc(100vh - 70px);

  //overflow: auto;
  h4 {
    font-weight: 600;
    font-size: 20px;
    line-height: 21px;
    letter-spacing: 0.1px;
    color: #333333;
  }

  &__header {}

  &__tab {
    padding: 10px;

    //word-break-wrap: none;
    &__date {
      font-weight: 500;
      font-size: 14px;
      line-height: 15px;
      text-align: center;
      color: #999999;
      cursor: pointer;
    }

    &__date__active {
      //width: 50px;
      height: 63px;
      background: #2C80FF;
      border-radius: 39px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p {
        font-weight: 500;
        font-size: 11px;
        line-height: 15px;
        text-align: center;
        color: #FFF;
      }

      ;
    }
  }

  &__empty {
    //height: 100%;
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 200px);

  }

  &__item {
    background: #FFFFFF;
    border-radius: 25px;
    padding: 15px 20px;
    margin-top: 15px;

    &__title {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.1px;
      color: #333333;
    }

    &__time {
      background-color: $input-color;
      border-radius: 10px;
      padding: 10px;
      margin: 10px 0px;
      width: 100%;

      span {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 15px;
        color: #333333;
      }
    }

    &__info {
      font-weight: 500;
      font-size: 12px;
      line-height: 122.5%;
      letter-spacing: 0.1px;
      color: #333333;
    }
  }

}