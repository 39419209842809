$font-body: "SVN-Gilroy", sans-serif !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-semibold: 600 !default;
$font-weight-bold: 700 !default;

$primary-bg-color: #fff !default;
$primary-color: #6576FF;
$secondary-color: #f8f8f8;
$black-color: #333333;
$white-color: #ffffff;
$red-color: #ff5855;
$green-color: #4BB7AB;
$green-light-color: #eff9f8;
$grey-dark-color: #666666;
$gray-light-color: #999999;
$grey-border-color: #e6e6e6;
$grey-bg-color: #dcdbe0;
$grey-light-bg-color: #f8f8f8;
$green-table-boder: #62c78f;
$blue-dark-color: #2b3042;
$blue-bg-color: #393e50;
$grey-table-border: #cccccc;
$green-table-bg: #35b46f;
$yellow-light-checkbox: #f6a447;
$list-border: #eeeeee;
$bg-grey: #d9d9d9;
$gray-1: #fcfcfc;
$border-color: #e2e2e2;
$table-bg: #f4f4f4;
$main-color : #2C80FF;
$text-primary : #050C42;
$input-color : #F4F7FF;
$header-bg : #050C42;
$green-light:#4BB7AB;

$lightest: #F5F6FA;
$dark:#4F5675;
$font-size-base: 1.4rem;

$button-height: 3.5rem;

$button-height-small: 3rem;

$input-hegiht: 4rem;

$input-hegiht-small: 3rem;

$border-radius-base: 2px;
$border-radius-sm: 2px;
// control border
$control-border-radius: $border-radius-base;

$btn-border-radius-base: 2px;
$btn-border-radius-sm: 2px;

$btn-primary-color: #fff;
$btn-primary-bg: $primary-color;

$header-height : 50px;
$main-content-height : calc(100vh - $header-height);
$main-sider-width : 250px;
$secondary-header-height : 64px;
// colors
$navy1: #2c3782;
$green1: #2cb2a5;
$orange1: #f6a447;
$orange2: #fff5e9;
$purple1: #7b61ff;
$red1: #f27e7c;
$blue2: #4263eb;
$green2: #e2fffc;
$grayLine: #e2e2e2;
$red2: #ffdada;
$red3: #f34946;
$blue3: #f5f7fb;
$textgray: #666666;
// sizes
$header-height: 50px;
$main-color: #6576ff;
$white: #fff;
