.custom__select {
  width: 100%;
  .ant-select-selector{
    //padding: 20px !important;
    //height: 50px !important;
    border: none !important;
    background-color: #F4F7FF !important;
    border-radius: 8px;

    &:hover {
      border: 1px solid #4971ED !important;
      box-shadow: none !important;
      border: none !important;
    }

    .ant-select-selection-item {
      //padding: 0 !important;
      font-weight: 500;
      // line-height: 19px;
      letter-spacing: 0.1px;
      color: #333333;
      // margin-top: 15px;
      //padding: 20px;
    }
  }
  .ant-select-selection-placeholder {
    // line-height: 50px !important;
    letter-spacing: 0.1px;
  }

  input {
    // line-height: 50px;
    // height: 50px !important;
    background-color: transparent;
    font-weight: 500;
    
  }
}

.custom__select-2 {
  background-color: #F4F7FF !important;
  border-radius: 10px !important;
  .ant-select-selector {
  border-radius: 12px !important;
  height: 40px !important;
  border: none !important;
  background-color: #F4F7FF !important;
  }
  input {
      height: 40px !important;
  }
  .ant-select-selection-placeholder {
      line-height: 40px !important;
  }
  .ant-select-selection-item {
      line-height: 40px !important;
  }
  
}

.custom__select__option {
  .ant-select-item-option-content{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    // line-height: 22px;
    letter-spacing: 0.1px;
    color: #333333;
  }
}

.custom__select--bold__600 {
  .ant-select-selector{
    .ant-select-selection-item {
      font-weight: 600;
    }
  }
  input {
    font-weight: 600; 
  }
}

.custom__select--bold__700 {
  .ant-select-selector{
    .ant-select-selection-item {
      font-weight: 700;
    }
  }
  input {
    font-weight: 700; 
  }
  
}

.custom__tree__select {
  background-color: $input-color;
    border-radius: 10px;
    .ant-select-selector {
      height: 40px;
    background-color: $input-color !important;
    border: transparent !important;
    border-radius: 10px;
    .ant-select-selection-item {
      background-color: $main-color;
      color:#fff;
      svg {
        fill : #fff;
      }
    }
  }
}