@import "base/all";
@import "modules/all";
@import "layout/all";
@import "pages/all";


.style-scroll-bar {
    /* width */
    &::-webkit-scrollbar {
        width: 14px;
    }
    /* Handle */
    &::-webkit-scrollbar-track {
        color: transparent;
        background-color: transparent;
        &:hover {
            background-color: #ddd;
          }
    }

    &::-webkit-scrollbar-thumb {
      background: #f6f8fe;
      &:hover {
        background-color: #a7aab1;
      }
      transition: all 1s linear;
    }
    
  }

.red-txt {
  color:red !important;
}
.blue-txt {
  color:#2C80FF !important;
}


.block-display {
  display: block !important;
}

.border-less {
  border:none !important;
}

.clinic-services {
  &__tree {
    width: 100%;

    .ant-tree-list-holder-inner {
      display: flex;
      flex-direction: row !important;
      flex-wrap: wrap;

      > .ant-tree-treenode {
        width: 50%;
        flex: 50%;

        &.parent {
          flex: 100%;
          width: 100%;
        }
      }
    }
  }
}