.main__header {
  width: 100%;
  background-color: #FFFFFF;
  height: $header-height;
  position: sticky;
  top: 0px;
  padding: 5px 20px;

  h3 {
    font-weight: 600 !important;
    font-size: 30px;
    line-height: 34px;
    letter-spacing: 0.1px;
    color: #333333;
    font-style: normal;
  }

  &__info {
    background-color: #FFFFFF;

    &__name {
      font-weight: 600;
      font-size: 14px;
      line-height: 15px;
      color: #333333;
    }

    &__phone {
      font-weight: 600;
      font-size: 12px;
      line-height: 13px;
      color: #0C49F6;
    }

    .ant-segmented {
      width: 100px;
      background-color: #F4F7FF;
      //background-color: red;
      border-radius: 30px !important;

      .ant-segmented-group {
        align-items: center;
        justify-content: center;
      }

      .ant-segmented-item-selected {
        border-radius: 25px;
        width: 45.04px;
        height: 30px;

      }
    }
  }
}