.custom__radio {
  width: 100%;
  //background-color: #4AB7AB;
  .ant-space-vertical {
    width: 100% !important;
  }
  .ant-radio-wrapper {
    background-color: #F4F7FF;
    padding: 20px;
    border-radius: 10px;
    color: #333333;
    width: 100% !important;
  }

  .ant-radio-wrapper-checked {
    background-color: #4971ED;
    color: #FFFFFF;
    .ant-radio-inner {
      background-color: #FFFFFF;
    }
    .ant-radio-inner:after {
      background-color: #4971ED;
    }
  }
}
